export const trymItems = [
  {
    type: 'separator',
    name: 'Personal',
    link: '',
    route: '',
    icon: '',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Empleados',
    link: 'personal',
    route: '/apps/trym',
    icon: 'people',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false
    },
    permission: null
  },
  // {
  //   type: 'link',
  //   name: 'Rutas',
  //   link: 'rutas',
  //   route: '/apps/trym',
  //   icon: 'timeline',
  //   role: {
  //     ROLE_SADMIN: true,
  //     ROLE_ADMIN: true,
  //     ROLE_TECHNICAL: false,
  //     ROLE_CLIENT: false
  //   },
  //   permission: null
  // },
  {
    type: 'link',
    name: 'Historico',
    link: 'historico',
    route: '/apps/trym',
    icon: 'history',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false
    },
    permission: null
  },
  {
    type: 'separator',
    name: 'Dashboard',
    link: '',
    route: '',
    icon: '',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'POL/TLC',
    link: 'pedidos',
    route: '/apps/trym/dashboard',
    icon: 'show_chart',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false
    },
    permission: null
  },
  {
    type: 'separator',
    name: 'Pedidos',
    link: '',
    route: '',
    icon: '',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Distribucion',
    link: 'distribucion',
    route: '/apps/trym',
    icon: 'list_alt',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false
    },
    permission: null
  },
  {
    type: 'separator',
    name: 'Carga/Descarga',
    link: '',
    route: '',
    icon: '',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: true
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Gestion Muelles',
    link: 'gestion-de-muelles',
    route: '/apps/trym',
    icon: 'settings',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false
    },
    permission: null
  },
];
