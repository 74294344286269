import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import * as CryptoJS from 'crypto-js';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private userService: UserService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem('token');

    if (token) {
        let headers = request.headers.set('Authorization', token);
        if (!request.headers.has('Content-Type')) {
            headers = headers.set('Content-Type', 'application/json');
        }
        request = request.clone({ headers });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (error.error.message === 'Sesión expirada, acceda de nuevo a la aplicación') {
            const user = this.userService.getUserIdentity();
            return from(this.userService.refreshTokenApi(user))
              .pipe(
                switchMap(response => {
                  if (!response.data) {
                    this.logout();
                  } else {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('UserIdentity', CryptoJS.AES.encrypt(
                      JSON.stringify(response.data.user), 'cryptoJuyro').toString());
                    const refreshToken = this.userService.getToken();
                    request = request.clone({ headers: request.headers.set('Authorization', refreshToken) });
                    return next.handle(request);
                  }
                })
              );
          }
        }
        return throwError(error);
      }));
  }

  logout() {
    localStorage.removeItem('UserIdentity');
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }
}
