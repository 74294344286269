import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';


@Injectable()
export class AuthGuardService implements CanActivate {
  public userIdentity;

  constructor(
    private router: Router,
    private userService: UserService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    if (this.userAuthenticated()) {

      this.userIdentity = this.userService.getUserIdentity();
      const path = route.data.path;

      const permissionsMap = {
        inicio: true,
        pysecor: this.userIdentity.permissions.appPysecor,
        consmare: this.userIdentity.permissions.consmare,
        mangecor: this.userIdentity.permissions.mangecor,
        trym: this.userIdentity.permissions.appTrymDistribucion,
        distribucion: this.userIdentity.permissions.appTrymDistribucion,
        ioDocuments: this.userIdentity.permissions.iODocuments,
        dashboard: this.userIdentity.permissions.dashboard,
        admin: this.userIdentity.permissions.admin,
        facturacion: this.userIdentity.permissions.billing,
        controlPedidosPolTlc: this.userIdentity.permissions.controlOrdersPolTlc,
        products: this.userIdentity.permissions.products,
        foodSafety: this.userIdentity.permissions.foodSafety,
        dataSheet: this.userIdentity.permissions.foodSafety,
        adminDataSheet: this.userIdentity.permissions.dataSheet,
        adminTraceabilityFruit: this.userIdentity.permissions.adminTraceabilityFruit,
        adminTraceabilityFish: this.userIdentity.permissions.adminTraceabilityFish,
        coaren: this.userIdentity.permissions.coaren,
        project: this.userIdentity.permissions.project,
        spacesAds: this.userIdentity.permissions.spacesAds
      };

      if (permissionsMap[path]) {
        return true;
      }
    }

    Swal.fire({
      title: 'No tienes permiso para acceder a este sitio',
      icon: 'info'
    });

    this.router.navigate(['/']);
    return false;
  }

  private userAuthenticated(): boolean {
    if (localStorage.getItem('token')) {
      return true;
    } else {
      return false;
    }
  }

}
