
export const traceabilityItems = [
  {
    type: 'separator',
    name: 'Trazabilidad',
    link: '',
    route: '',
    icon: '',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: true
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Fruta',
    link: 'fruta',
    route: '/apps/seguridad-alimentaria/trazabilidad',
    icon: 'compost',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: true
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Pescado',
    link: 'pescado',
    route: '/apps/seguridad-alimentaria/trazabilidad',
    icon: 'list_alt',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: true
    },
    permission: null
  }
]
