import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from './layout/layout.component';
import { LayoutAdminComponent } from './layout/layout-admin/layout-admin.component';

import { AuthGuardService } from './services/auth-guard.service';
import { IsLoginService } from './services/isLogin.service';
import { CURRENT_PROVIDER } from './services/work.service';

const createSharedWorks = (currentProvider: string): Routes =>  [
  {
    path: '',
    redirectTo: 'trabajos',
    pathMatch: 'full'
  },
  {
    path: 'clientes',
    loadChildren: () => import('./core/admin/client/client.module').then(m => m.ClientModule),
    canActivate: [AuthGuardService],
    providers: [
      { provide: CURRENT_PROVIDER, useValue: currentProvider }
    ]
  },
  {
    path: 'personal',
    loadChildren: () => import('./core/admin/employee/employee.module').then(m => m.EmployeeModule),
    canActivate: [AuthGuardService],
    providers: [
      { provide: CURRENT_PROVIDER, useValue: currentProvider }
    ]
  },
  {
    path: 'trabajos',
    loadChildren: () => import('./core/works/work/work.module').then(m => m.WorkModule),
    canActivate: [AuthGuardService],
    providers: [
      { provide: CURRENT_PROVIDER, useValue: currentProvider }
    ]
  },
  {
    path: 'categorias',
    loadChildren: () => import('./core/works/category/category.module').then(m => m.CategoryModule),
    canActivate: [AuthGuardService],
    providers: [
      { provide: CURRENT_PROVIDER, useValue: currentProvider }
    ]
  },
  {
    path: 'elementos',
    loadChildren: () => import('./core/works/element/element.module').then(m => m.ElementModule),
    canActivate: [AuthGuardService],
    providers: [
      { provide: CURRENT_PROVIDER, useValue: currentProvider }
    ]
  },
  {
    path: 'resumen',
    loadChildren: () => import('./core/works/resumen/resumen.module').then(m => m.ResumenModule),
    canActivate: [AuthGuardService],
    providers: [
      { provide: CURRENT_PROVIDER, useValue: currentProvider }
    ]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./core/works/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuardService],
    providers: [
      { provide: CURRENT_PROVIDER, useValue: currentProvider }
    ]
  }
];

const routes: Routes = [
  {
    path: '',
    canActivate: [IsLoginService],
    children: [
      {
        path: '',
        redirectTo: '/authentication/login',
        pathMatch: 'full'
      },
      {
        path: 'authentication',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },
  {
    path: 'apps',
    children: [
      {
        path: '',
        redirectTo: '/apps/inicio',
        pathMatch: 'full'
      },
      {
        path: 'inicio',
        component: LayoutComponent,
        loadChildren: () => import('./core/apps/apps.module').then(m => m.AppsModule),
        canActivate: [AuthGuardService],
        data: {
          path: 'inicio'
        }
      },
      {
        path: 'pysecor',
        component: LayoutAdminComponent,
        canActivate: [AuthGuardService],
        data: {
          path: 'pysecor'
        },
        children: createSharedWorks('pysecor').map(child => ({
          ...child,
          data: { path: 'pysecor' },
        }))
      },
      {
        path: 'consmare',
        component: LayoutAdminComponent,
        canActivate: [AuthGuardService],
        data: {
          path: 'consmare'
        },
        children: createSharedWorks('consmare').map(child => ({
          ...child,
          data: { path: 'consmare' },
        }))
      },
      {
        path: 'mangecor',
        component: LayoutAdminComponent,
        canActivate: [AuthGuardService],
        data: {
          path: 'mangecor'
        },
        children: createSharedWorks('mangecor').map(child => ({
          ...child,
          data: { path: 'mangecor' },
        }))
      },
      {
        path: 'trym',
        component: LayoutAdminComponent,
        canActivate: [AuthGuardService],
        data: {
          path: 'trym'
        },
        children: [
          {
            path: '',
            redirectTo: '/apps/trym/distribucion',
            pathMatch: 'full'
          },
          {
            path: 'distribucion',
            loadChildren: () => import('./core/trym/distribucion/orders/orders.module').then(m => m.OrdersModule),
            canActivate: [AuthGuardService],
            data: {
              path: 'distribucion'
            }
          },
          {
            path: 'personal',
            loadChildren: () => import('./core/admin/employee/employee.module').then(m => m.EmployeeModule),
            canActivate: [AuthGuardService],
            data: {
              path: 'trym'
            }
          },
          {
            path: 'dashboard',
            loadChildren: () => import('./core/dashboard/pedidos/pedidos.module').then(m => m.PedidosModule),
            canActivate: [AuthGuardService],
            data: {
              path: 'trym'
            },
          },
          {
            path: 'historico',
            loadChildren: () => import('./core/trym/distribucion/history-employee/history-employee.module').then(m => m.HistoryEmployeeModule),
            canActivate: [AuthGuardService],
            data: {
              path: 'trym'
            },
          },
          {
            path: 'gestion-de-muelles',
            loadChildren: () => import('./core/trym/platforms/platforms-trym.module').then(m => m.PlatformsTrymModule),
            canActivate: [AuthGuardService],
            data: {
              path: 'trym'
            },
          },
        ]
      },
      {
        path: 'controlPedidosPolTlc',
        component: LayoutComponent,
        loadChildren: () => import('./core/dashboard/pedidos/pedidos.module').then(m => m.PedidosModule),
        canActivate: [AuthGuardService],
        data: {
          path: 'controlPedidosPolTlc'
        }
      },
      {
        path: 'ioDocuments',
        component: LayoutComponent,
        loadChildren: () => import('./core/ioDocuments/ioDocuments.module').then(m => m.IODocumentsModule),
        canActivate: [AuthGuardService],
        data: {
          path: 'ioDocuments'
        }
      },
      {
        path: 'facturacion',
        component: LayoutComponent,
        loadChildren: () => import('./core/billing/billing.module').then(m => m.BillingModule),
        canActivate: [AuthGuardService],
        data: {
          path: 'facturacion'
        }
      },
      {
        path: 'seguridad-alimentaria',
        loadChildren: () => import('./core/foodSafety/food-safety.module').then(m => m.FoodSafetyModule),
        canActivate: [AuthGuardService],
        data: {
          path: 'foodSafety'
        }
      },
      {
        path: 'coaren',
        component: LayoutComponent,
        loadChildren: () => import('./core/coaren/coaren.module').then(m => m.CoarenModule),
        canActivate: [AuthGuardService],
        data: {
          path: 'coaren'
        }
      },
      {
        path: 'proyectos',
        component: LayoutComponent,
        loadChildren: () => import('./core/project/project.module').then(m => m.ProjectModule),
        canActivate: [AuthGuardService],
        data: {
          path: 'project'
        }
      },
      {
        path: 'espacios-publicitarios',
        component: LayoutAdminComponent,
        loadChildren: () => import('./core/spacesAds/spaces-ads.module').then(m => m.SpacesAdsModule),
        canActivate: [AuthGuardService],
        data: {
          path: 'spacesAds'
        },
      }
    ]
  },

  {
    path: 'admin',
    component: LayoutAdminComponent,
    canActivate: [AuthGuardService],
    data: {
      path: 'admin'
    },
    children: [
      {
        path: '',
        redirectTo: '/admin/clientes',
        pathMatch: 'full'
      },
      {
        path: 'empresas',
        loadChildren: () => import('./core/admin/company/company.module').then(m => m.CompanyModule),
        canActivate: [AuthGuardService],
        data: {
          path: 'admin'
        }
      },
      {
        path: 'clientes',
        loadChildren: () => import('./core/admin/client/client.module').then(m => m.ClientModule),
        canActivate: [AuthGuardService],
        data: {
          path: 'admin'
        },
        providers: [
          { provide: CURRENT_PROVIDER, useValue: '' }
        ]
      },
      {
        path: 'departamentos',
        loadChildren: () => import('./core/admin/department/department.module').then(m => m.DepartmentModule),
        canActivate: [AuthGuardService],
        data: {
          path: 'admin'
        }
      },
      {
        path: 'empleados',
        loadChildren: () => import('./core/admin/employee/employee.module').then(m => m.EmployeeModule),
        canActivate: [AuthGuardService],
        data: {
          path: 'admin'
        }
      },
      {
        path: 'utilidades',
        loadChildren: () => import('./core/admin/utility/utility.module').then(m => m.UtilityModule),
        canActivate: [AuthGuardService],
        data: {
          path: 'admin'
        }
      },
      {
        path: 'config',
        loadChildren: () => import('./core/admin/config/config.module').then(m => m.ConfigModule),
        canActivate: [AuthGuardService],
        data: {
          path: 'admin'
        }
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'authentication/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
