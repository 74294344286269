<div class="container">
  <mat-toolbar class="mat-elevation-z6 toolbal" color="primary">
    <img class="logo" src="assets/images/logo.png" matTooltip="JUYRO 1961 S.L">
    <div class="div-icons">
      <a (click)="snav.toggle()">
        <mat-icon class="icons">menu</mat-icon>
      </a>
      <a [matMenuTriggerFor]="menu" matTooltip="Usuario" class="icon-user">
        <mat-icon class="icons">person</mat-icon>
      </a>
      <a routerLink="/apps" matTooltip="Aplicaciones">
        <mat-icon class="icons">apps</mat-icon>
      </a>
      <a routerLink="/" (click)="logout()" matTooltip="Salir de la aplicacion">
        <mat-icon class="icons">exit_to_app</mat-icon>
      </a>
    </div>
  </mat-toolbar>

  <mat-menu #menu="matMenu">
    <button mat-menu-item>
      <mat-icon>email</mat-icon>
      {{UserIdentity.email}}
    </button>
    <button mat-menu-item [matMenuTriggerFor]="password" *ngIf="UserIdentity.role != 'ROLE_CLIENT'">
      <mat-icon>lock</mat-icon>
      Cambiar contraseña
    </button>
  </mat-menu>

  <mat-menu #password="matMenu">
    <mat-card>
      <mat-card-content (click)="$event.stopPropagation();">
        <form [formGroup]="formPassword"  (keydown.tab)="$event.stopPropagation();">
          <mat-form-field>
            <label>Contraseña actual</label>
            <input matInput type="password" formControlName="currentPassword" name="currentPassword">
          </mat-form-field>
          <mat-form-field>
            <label>Contraseña</label>
            <input matInput type="password" formControlName="password" name="password">
          </mat-form-field>
          <mat-error *ngIf="formPassword.hasError('errorPass')" class="text-danger support-text">
            La contraseña debe tener entre 8 y 16 caracteres, al menos un dígito, una minúscula y una mayúscula.
            No puede tener espacios en blanco.</mat-error>
          <mat-form-field>
            <label>Confirmar contraseña</label>
            <input matInput type="password" formControlName="confirmPass" name="confirmPass">
          </mat-form-field>
          <mat-error *ngIf="formPassword.hasError('errorEqualPass')">Las contraseñas no son iguales</mat-error>
          <div class="container-buttons">
            <button mat-flat-button type="button" color="primary" (click)=updatePassword()
              [disabled]="!formPassword.valid">
              Cambiar
            </button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </mat-menu>

  <mat-sidenav-container class="sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" opened [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56">
      <mat-nav-list>
        <div *ngFor="let option of menuItems">
          <div *ngIf="option.type === 'separator'">
            <div mat-subheader>{{option.name}}</div>
          </div>
          <div *ngIf="option.type === 'link'">
            <mat-list-item [routerLink]="[option.route, option.link]" routerLinkActive="routerLinkActive">
              <mat-icon mat-list-icon>{{option.icon}}</mat-icon>
              <div mat-line>{{option.name}}</div>
            </mat-list-item>
          </div>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <h1 *ngIf="path === 'admin'">ADMINISTRACION</h1>
      <h1 *ngIf="path === 'dashboard'">DASHBOARD</h1>
      <h1 *ngIf="path === 'pysecor'">PYSECOR</h1>
      <h1 *ngIf="path === 'consmare'">CONSMARE</h1>
      <h1 *ngIf="path === 'mangecor'">MANGECOR</h1>
      <h1 *ngIf="path === 'trym'">TRYM</h1>
      <router-outlet>
      </router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

