import { Component} from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { User } from '../models/user';

@Component({
  selector: 'app-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.scss']
})
export class LayoutComponent {
  public UserIdentity;
  public formPassword: FormGroup;
  public password: string;
  public user: User;

  constructor(
    private userService: UserService,
    private router: Router,
    private fb: FormBuilder,
    ) {
    this.UserIdentity = userService.getUserIdentity();
    this.formPassword = this.fb.group({
      currentPassword: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPass: ['', [Validators.required]],
    }, { validator: [this.validatePasswords, this.validateEqualsPasswords ]});
  }

  validatePasswords(group: FormGroup) {
    const regexp = new RegExp(/^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/);
    const pass = group.controls.password.value;

    if (regexp.test(pass)) {
      return null;
    } else {
      return { errorPass: true };
    }
  }

  validateEqualsPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPass.value;
    if (pass === confirmPass) {
      return null;
    } else {
      return { errorEqualPass: true };
    }
  }

  updatePassword() {

    if (this.formPassword.value.password !== '') {
      this.formPassword.value._id = this.UserIdentity._id;
      this.userService.updatePassword(this.formPassword.value).subscribe(
        response => {
          if (!response.user) {
            Swal.fire({ title: response.message, icon: 'warning' });
          } else {
            Swal.fire({ title: 'Contraseña modificada', icon: 'success' });
            this.formPassword.reset();
          }
        },
        error => {
          if (error != null) {
            if (error.error.message) {
              Swal.fire({ title: error.error.message, icon: 'warning' });
            } else {
              Swal.fire({ title: 'Error de conexion', icon: 'warning' });
            }
          }
        }
      );
    }
  }

  logout() {
    localStorage.removeItem('UserIdentity');
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }

}
