// Menu dashboard
export const dashboardItems = [
  {
    type: 'separator',
    name: 'Pysecor',
    link: '',
    route: '',
    icon: '',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Partes tiendas',
    link: 'partes-tiendas',
    route: '/apps/dashboard/pysecor',
    icon: 'insert_chart',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Personal',
    link: 'personal',
    route: '/apps/dashboard/pysecor',
    icon: 'table_chart',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false
    },
    permission: null
  },
  {
    type: 'separator',
    name: 'Trym',
    link: '',
    route: '',
    icon: '',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Pedidos',
    link: 'pedidos',
    route: '/apps/dashboard/trym',
    icon: 'list_alt',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false
    },
    permission: null
  },
  {
    type: 'separator',
    name: 'Fichas tecnicas',
    link: '',
    route: '',
    icon: '',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Datos',
    link: 'fichas',
    route: '/apps/dashboard',
    icon: 'analytics',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false
    },
    permission: null
  },
];
