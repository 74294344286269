import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuItems, Menu } from 'src/app/shared/menu-items/menu-items';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-layout-admin',
  templateUrl: 'layout-admin.component.html',
  styleUrls: ['layout-admin.component.scss']
})
export class LayoutAdminComponent implements OnDestroy, OnInit {
  mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;
  public menuItems: Menu[] = [];
  public path: string;
  public UserIdentity;
  public formPassword: FormGroup;
  public password: string;
  public user: User;

  constructor(
    private router: Router,
    public menuItemsService: MenuItems,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private fb: FormBuilder,
  ) {
    this.UserIdentity = userService.getUserIdentity();
    this.formPassword = this.fb.group({
      currentPassword: [''],
      password: ['', [Validators.required]],
      confirmPass: ['', [Validators.required]],
    }, { validator: [this.validatePasswords, this.validateEqualsPasswords] });

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
  }

  ngOnInit() {
    this.activatedRoute.url.subscribe(url => {
      if (url.length) {
        this.path = url[0].path;
        const auxMenuItems = this.menuItemsService.getMenuItems(url[0].path);
        let index = 0;
        auxMenuItems.forEach(element => {
          if (element.role[this.UserIdentity.role]) {
            if (!element.permission) {
              this.menuItems[index++] = element;
            } else {
              if (this.UserIdentity.permissions[Object.keys(element.permission)[0]]) {
                this.menuItems[index++] = element;
              }
            }
          }
        });
      }
    });
  }

  validatePasswords(group: FormGroup) {
    const regexp = new RegExp(/^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/);
    const pass = group.controls.password.value;

    if (regexp.test(pass)) {
      return null;
    } else {
      return { errorPass: true };
    }
  }

  validateEqualsPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPass.value;
    if (pass === confirmPass) {
      return null;
    } else {
      return { errorEqualPass: true };
    }
  }

  updatePassword() {

    if (this.formPassword.value.password !== '') {
      this.formPassword.value._id = this.UserIdentity._id;
      this.userService.updatePassword(this.formPassword.value).subscribe(
        response => {
          if (!response.user) {
            Swal.fire({ title: response.message, icon: 'warning' });
          } else {
            Swal.fire({ title: 'Contraseña modificada', icon: 'success' });
            this.formPassword.reset();
          }
        },
        error => {
          if (error != null) {
            if (error.error.message) {
              Swal.fire({ title: error.error.message, icon: 'warning' });
            } else {
              Swal.fire({ title: 'Error de conexion', icon: 'warning' });
            }
          }
        }
      );
    }
  }


  logout() {
    localStorage.removeItem('UserIdentity');
    localStorage.removeItem('token');
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

}
