import { Injectable } from '@angular/core';
import { adminItems } from './adminItems';
import { dashboardItems } from './dashboardItems';
import { dataSheetsItems } from './dataSheetsItems';
import { spaceAdsItems } from './spaceAdsItems';
import { trymItems } from './trymItems';
import { pysecorItems } from './pysecorItems';
import { consmareItems } from './consmareItems';
import { mangecorItems } from './mangecorItems';
import { docsFoodSafetyItems } from './docsFoodSafety';
import { traceabilityItems } from './traceability';

export interface Menu {
  name: string;
  link: string;
  route: string;
  icon: string;
  type: string;
  role: {};
  permission: {};
}

@Injectable()
export class MenuItems {

  getMenuItems(items): Menu[] {

    const menuItems = {
      dashboard: dashboardItems,
      admin: adminItems,
      pysecor: pysecorItems,
      consmare: consmareItems,
      mangecor: mangecorItems,
      trym: trymItems,
      'espacios-publicitarios': spaceAdsItems,
      fichas: dataSheetsItems,
      documentacion: docsFoodSafetyItems,
      trazabilidad: traceabilityItems
    };

    return menuItems[items];
  }
}
