import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class IsLoginService implements CanActivate {

  constructor(
    private router: Router
  ) { }

  canActivate() {
    if (!this.isLogin()) {
        return true;
    }
    this.router.navigate(['/apps']);
  }

  private isLogin() {
    if (localStorage.getItem('token')) {
      return true;
    } else {
      return false;
    }
  }
}
