import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class UserService {

  public UserIdentity;
  public token: string;

  constructor(
    private http: HttpClient
  ) {}

  login(userLogin, getHash = null) {
    if ( getHash != null ) {
      userLogin.getHash = getHash;
    }
    return this.http.post<any>(environment.apiUrl + 'login', userLogin);
  }

  getUserIdentity() {
    const UserIdentity = localStorage.getItem('UserIdentity');

    if (UserIdentity !== 'undefined') {
      this.UserIdentity = UserIdentity;
    } else {
      this.UserIdentity = null;
    }

    const cryptoUserindentity = CryptoJS.AES.decrypt(this.UserIdentity, 'cryptoJuyro');

    return JSON.parse(cryptoUserindentity.toString(CryptoJS.enc.Utf8));
  }

  getToken() {
    const token = localStorage.getItem('token');

    if (token !== 'undefined') {
      this.token = token;
    } else {
      this.token = null;
    }
    return this.token;
  }

  activeUser(params) {
    return this.http.post<any>(environment.apiUrl + 'activeUser', params);
  }

  userCheck(email: string) {
    return this.http.get<any>(environment.apiUrl + 'userCheck/' + email);
  }

  refreshTokenApi(user) {
    return this.http.post<any>(environment.apiUrl + 'refreshTokenApi', user);
  }

  sendNotificationsByEmail(notifications) {
    return this.http.post<any>(environment.apiUrl + 'sendNotificationsByEmail', notifications);
  }

  updatePassword(updatePassword) {
    return this.http.post<any>(environment.apiUrl + 'updatePassword', updatePassword);
  }
}



