import { NgModule } from '@angular/core';

// Libreria Material
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'; import {MatExpansionModule} from '@angular/material/expansion';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'; import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDividerModule } from '@angular/material/divider'; import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatIconModule } from '@angular/material/icon'; import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatRippleModule } from '@angular/material/core'; import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table'; import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatGridListModule } from '@angular/material/grid-list'; import { MatSortModule } from '@angular/material/sort';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list'; import {MatSidenavModule} from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar'; import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs'; import {MatBadgeModule} from '@angular/material/badge';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar'; import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core'; import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip'; import {MatTreeModule} from '@angular/material/tree';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu'; import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { DragDropModule } from '@angular/cdk/drag-drop'; import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import { LayoutModule } from '@angular/cdk/layout'; import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './custom-date-adapter';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';

@NgModule({
    declarations: [],
    imports: [
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        MatDividerModule,
        MatIconModule,
        MatCardModule,
        MatRippleModule,
        MatDialogModule,
        MatTableModule,
        MatFormFieldModule,
        MatPaginatorModule,
        MatSortModule,
        MatGridListModule,
        MatListModule,
        MatSidenavModule,
        MatToolbarModule,
        MatTabsModule,
        MatBadgeModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatTreeModule,
        MatMenuModule,
        MatBottomSheetModule,
        DragDropModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatSlideToggleModule,
        LayoutModule
    ],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
    ],
    exports: [
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        MatDividerModule,
        MatIconModule,
        MatCardModule,
        MatRippleModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatGridListModule,
        MatListModule,
        MatSidenavModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatTabsModule,
        MatBadgeModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatTreeModule,
        MatMenuModule,
        MatBottomSheetModule,
        DragDropModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatSlideToggleModule
    ]
})

export class MaterialModule { }
